import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"


const Stroy = ({ data }) => {
  return (
    <Layout>
      <div className="mt-8">
        <div className="bg-transparent">
          <h2
            className="font-bold text-2xl md:text-3xl lg:text-4xl xl:text-4xl"
            style={{
              fontFamily: `Montserrat`,
              color: `white`,
              padding: `1rem`,
            }}
          >
            СТРОИТЕЛЬСТВО ДОМОВ
          </h2>
        </div>
      </div>
      <div className="grid grid-cols-1 mt-20 md:grid-cols-2 lg:grid-cols-2">
        {data.allContentfulStroitelstvodomov.nodes.map(stroy => (
          <div key={stroy.id}>
            <div className="w-full flex flex-col mb-6 md:flex-col xl:flex-col">
              <div className="flex p-2 items-start">
                <Img
                  fluid={stroy.image.fluid}
                  style={{
                    width: `90%`,
                    height: `20rem`,
                  }}
                  imgStyle={{ objectFit: "cover", height: "90%" }}
                />
              </div>
              <div className="p-2 ">
                <h3
                  className="mb-4 text-1xl md:text-2xl lg:text-2xl xl:text-2xl"
                  style={{
                    fontFamily: `Montserrat`,
                    color: `#F1F1F2`,
                  }}
                >
                  {stroy.title}
                </h3>
                <span
                  className="mt-8 text-sm md:text-sm lg:text-sm xl:text-sm"
                  style={{
                    fontFamily: `Montserrat`,

                    color: `#F1F1F2`,
                  }}
                >
                  {documentToReactComponents(stroy.description.json)}
                </span>
                <Link to={`/${stroy.slug}`}>
                  <button
                    className="mt-4 bg-transparent text-white uppercase font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                    style={{
                      fontFamily: `Montserrat`,
                      fontSize: `0.7rem`,
                    }}
                  >
                    подробнее
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default Stroy

export const PageQuery = graphql`
  query Stroydom {
    allContentfulStroitelstvodomov {
      nodes {
        title
        slug
        id
        description {
          json
        }
        image {
          fluid(maxWidth: 600, quality: 35) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
